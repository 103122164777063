<template>
    <Layout>
        <div class="container-exercises">
            <BreadCrumb :items="[
                { name: 'Banco de exercícios', path: '/exercicios/fisica' },
                { name: getSelectedCategory?.name, path: `/exercicios/${getSelectedCategory?.url.toLowerCase()}` },
                { name: getSubCategory?.sub_area.name, path: `/exercicios/${getSelectedCategory?.url.toLowerCase()}` },
                { name: getSubCategory?.name, path: `/exercicios/${getSelectedCategory?.url.toLowerCase()}` },
            ]" v-if="!getIsMobile" />

            <div class="page-header">
                <router-link v-if="getIsMobile" :to="`/exercicios/${getSelectedCategory?.url.toLowerCase()}`">
                    <IconSax type="outline" name="arrow-left" />
                </router-link>
                <h1 class="headline-semibold-24 color-base">{{ $t("banco_exercicios.title") }}</h1>
                <ButtonPlay type="normal" @click="handleDownloadPDF">
                    <div class="icon-button">
                        <IconSax type="bold" name="document-text" />
                        {{ $t("banco_exercicios.pdf") }}
                    </div>
                </ButtonPlay>
            </div>
            <div class="list-header">
                <h1 class="list-header__title body-semibold-18">{{ getSubCategory?.name }}</h1>
                <div class="list-header__answers">
                    <div class="list-header__answers__opt">
                        <IconSax type="bold" name="tick-circle" />
                        <span class="body-regular-14">{{ $t("banco_exercicios.acertos") }}</span>
                        <span class="body-regular-14">{{ getExercises?.correct_answers }}</span>
                    </div>
                    <div class="list-header__answers__opt">
                        <IconSax type="bold" name="close-circle" />
                        <span class="body-regular-14">{{ $t("banco_exercicios.erros") }}</span>
                        <span class="body-regular-14">{{ getExercises?.incorrect_answers }}</span>
                    </div>
                    <Divider type="vertical" :noMargin="true" />
                    <div class="list-header__answers__total">
                        <span class="body-regular-16">{{ getExercises?.correct_answers + getExercises?.incorrect_answers }}
                            / {{
                                getExercises?.exercises?.total }}</span>
                    </div>
                    <div class="list-header__reset_answers"
                        v-if="getExercises?.correct_answers + getExercises?.incorrect_answers > 0">
                        <ButtonPlay size="sm" @click="handleResetAnswers">
                            <IconSax name="refresh-right-square" />
                            <span class="body-semibold-14">{{ $t("banco_exercicios.clear") }}</span>
                        </ButtonPlay>
                    </div>
                </div>
            </div>
            <Divider />
            <div class="cardquestions">
                <CardQuestion v-for="(item, index) in getExercises.exercises?.data"
                    :key="`${index}-${item.member_answers.length > 0 ? item.member_answers[0].pivot.correct : null}`"
                    :title="`${item.institution?.name || 'Stoodi'} ${item.year || ''}`" :description="item.text"
                    :url="`/exercicios/${getSelectedCategory?.url.toLowerCase()}/${getSubCategory?.url.toLowerCase()}/${item.url}`"
                    :index="index + 1" :answer="item.member_answers.length > 0 ? item.member_answers[0].pivot : null" />
                <infinite-loading @infinite="infiniteHandler" v-if="!getIsLoading">
                    <div slot="no-more">
                        <p class="body-regular-14 color-base">{{ $t("banco_exercicios.exercicios") }}</p>
                    </div>
                    <div slot="no-results">
                        <p class="body-regular-14 color-base">{{ $t("banco_exercicios.exercicio") }}</p>
                    </div>
                </infinite-loading>
            </div>
        </div>
    </Layout>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import InfiniteLoading from 'vue-infinite-loading';

export default {
    components: {
        Layout: () =>
            import(
                "@/layout/Layout.vue"
            ),
        Divider: () =>
            import(
                "@/components/common/Divider.vue"
            ),
        ButtonPlay: () =>
            import(
                "@/components/common/ButtonPlay.vue"
            ),
        BreadCrumb: () =>
            import(
                "@/components/common/Breadcrumb.vue"
            ),
        CardQuestion: () =>
            import(
                "@/components/pages/exercises/CardQuestion.vue"
            ),
        InfiniteLoading
    },
    computed: {
        ...mapGetters({
            getMe: "auth/getMe",
            getExercises: "exercises/getExercises",
            getIsMobile: "layout/getIsMobile",
            getIsLoading: "config/getIsLoading",
            getSelectedCategory: "exercises/getSelectedCategory",
            getSubCategory: "exercises/getSubCategory",
            getAreasFromCategory: "exercises/getAreasFromCategory",
        }),
        exercisesAllowed() {
            if (
                this.$store.getters['user/metasSite'] &&
                this.$store.getters['user/metasSite'].exercises_allowed
            ) {
                return this.$store.getters['user/metasSite'].exercises_allowed === "1";
            } else return null;
        },
    },
    async created() {
        if (!this.exercisesAllowed) {
            this.$router.push({
                name: "Home",
            });
        }
        await this.actionSetIsLoading(true);
        const area = this.$route.params.area;
        await this.actionFetchExercisesAreas(area);
        const subCategory = this.$route.params.subCategory;
        await this.actionSetSubCategory(subCategory);
        if (this.$route.query.refresh) {
            await this.actionFetchExercisesByCategory({ area, subCategory, page: 1, refresh: true });
            this.page = 1;
        }
        await this.actionSetIsLoading(false);
    },
    data() {
        return {
            page: 0,
        }
    },
    methods: {
        ...mapActions({
            actionSetIsLoading: "config/actionSetIsLoading",
            actionFetchExercisesByCategory: "exercises/actionFetchExercisesByCategory",
            actionSetSubCategory: "exercises/actionSetSubCategory",
            actionFetchExercisesAreas: "exercises/actionFetchExercisesAreas",
            resetAnswers: "exercises/resetAnswers",
            downloadExercisesPDF: "exercises/downloadExercisesPDF",
            signedPDFUrl: "exercises/signedPDFUrl",
        }),
        async handleDownloadPDF() {
            const area = this.$route.params.area;
            const subCategory = this.$route.params.subCategory;

            // call a post to /exercises/{area}/{subCategory}/pdf and get the signed url and then open it in a new tab
            const { url } = await this.signedPDFUrl({ area, subCategory })


            window.open(url, "_blank");

        },
        handleResetAnswers() {
            const area = this.$route.params.area;
            const subCategory = this.$route.params.subCategory;
            this.resetAnswers({ area, subCategory });
        },
        infiniteHandler($state) {
            if (this.page === this.getExercises.exercises?.last_page) {
                $state.complete();
                return;
            }

            this.page += 1;
            const area = this.$route.params.area;
            const subCategory = this.$route.params.subCategory;
            this.actionFetchExercisesByCategory({ area, subCategory, page: this.page })
                .then(() => {
                    $state.loaded();
                })
                .catch(() => {
                    $state.error();
                });
        },
    },
};
</script>
  
<style lang="scss" scoped>
.container-exercises {
    padding-top: 1.5rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    padding-bottom: 8.31rem;

    @media screen and (max-width: 768px) {
        padding: 1rem;
    }
}

.cardquestions {
    @include flex-column;
    gap: 0.5rem;
    margin-top: 1.5rem;
}

.list-header {
    @include flex-row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    gap: 1rem;
    margin-bottom: 1.25rem;

    @media screen and (max-width: 768px) {
        @include flex-column;
        align-items: flex-start;
    }

    &__title {
        @include flex-row;
        justify-content: center;
        color: var(--fontcolor);
        align-items: center;
        margin: 0;
        padding: 0;
    }

    &__answers {
        @include flex-row;
        height: 100%;
        gap: 1rem;
        flex-wrap: wrap;

        &__opt {
            @include flex-row;
            gap: 0.25rem;
            align-items: center;
            color: var(--fontcolor);

            &:first-of-type {
                ::v-deep .svgCss {
                    svg {
                        width: 1rem;
                        height: 1rem;
                    }

                    svg path {
                        fill: var(--feedback-success-500);
                    }
                }
            }

            &:nth-of-type(2) {
                ::v-deep .svgCss {
                    svg {
                        width: 1rem;
                        height: 1rem;
                    }

                    svg path {
                        fill: var(--feedback-error-500);
                    }
                }
            }
        }

        &__total {
            @include flex-row;
            gap: 0.5rem;
            align-items: center;
            color: var(--fontcolor);
        }
    }
}

.page-header {
    @include flex-row;

    @media screen and (max-width: 768px) {
        @include flex-column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2.5rem;
        margin-top: 0rem;

        h1 {
            margin-bottom: 1rem;
            color: var(--fontcolor);
        }
    }

    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.75rem;
    margin-top: 2.5rem;

    a {
        @media screen and (max-width: 768px) {
            margin-bottom: 1.5rem;
        }
    }

    ::v-deep .svgCss {
        svg {
            width: 1.5rem;
            height: 1.5rem;
        }

        svg path {
            fill: var(--neutral-gray-800) !important;
        }
    }

    .icon-button {
        @include flex-row;
        align-items: center;
        gap: 0.5rem;

        ::v-deep .svgCss {
            svg {
                width: 1rem;
                height: 1rem;
            }

            svg path {
                fill: #fff !important;
            }
        }
    }
}
</style>
  